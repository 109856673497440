<template>
  <div class="onboarding-template--wrapper">
    <WebsiteBanner />
    <div class="page-container row" @keyup.enter="login">
      <div class="page-container--content">
        <div class="content">
          <h2 class="um-header--title">Thanks for signing up!</h2>
          <div>
            <p class="um-header--subtext">
              We've sent you an email. Check your inbox (spam also) and make
              sure to verify your email address before you
              <router-link :to="{ name: 'login' }" class="login-link">
                login
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebsiteBanner from '@c/components/headers/WebsiteBanner'

export default {
  components: { WebsiteBanner }
}
</script>

<style lang="scss"></style>
