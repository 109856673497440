var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "onboarding-template--wrapper" },
    [
      _c("WebsiteBanner"),
      _c(
        "div",
        {
          staticClass: "page-container row",
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.login.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "page-container--content" }, [
            _c("div", { staticClass: "content" }, [
              _c("h2", { staticClass: "um-header--title" }, [
                _vm._v("Thanks for signing up!")
              ]),
              _c("div", [
                _c(
                  "p",
                  { staticClass: "um-header--subtext" },
                  [
                    _vm._v(
                      " We've sent you an email. Check your inbox (spam also) and make sure to verify your email address before you "
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "login-link",
                        attrs: { to: { name: "login" } }
                      },
                      [_vm._v(" login ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }